import { Link } from 'react-router-dom';
import './assets/css/footer.css';
function Footer(){
    return(
        <footer className="footer">
            <div className='container-footer'>
                <div className='footer-main'>
                    <div className='img-footer-container'><img src="https://panterasteam.com/store/api/public/img/static/STORE.png" alt="Store" /></div>
                    <h1>Panteras Store</h1>
                </div>
                <div className='contact-footer'>
                    <Link>Contactanos</Link>
                    <Link>Nosotros</Link>
                    <Link>Instagram</Link>
                    <Link>Facebook</Link>
                    <Link>TikTok</Link>
                    <Link>YouTube</Link>
                </div>
            </div>
        </footer>
    );
};  
export default Footer;