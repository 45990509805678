import { Suspense } from 'react';
import { fetchData } from './fetch';
import { Link } from 'react-router-dom';
import { Container, Card, Row } from 'react-bootstrap';
import './assets/css/card.css';

const apiData = fetchData("https://panterasteam.com/store/api/src/interfaces/controllers/public.php");

function CardProduct() {
  const data = apiData.read();

  return (
    <Container>
      <Row className='cont-cards'>
        {data?.map((producto) => {
          return (
            <Card bg='#ffff' style={{ width: '14rem' }} className='card-product p-2 m-2' key={producto.idProducto}>
              <Link to={`/producto?id=${producto.idProducto}`}>
                <Card.Img variant="top" src={producto.path} />
                <Card.Body>
                  <Card.Title><b>{producto.nombre}</b></Card.Title>
                  {/* <Card.Text>
                    {producto.descripcion}<br />
                  </Card.Text> */}
                </Card.Body>
                <Card.Footer className='text-muted'>${producto.precio}</Card.Footer>
              </Link>
            </Card>
          );
        })}
      </Row>
    </Container>
  );
}

export default function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CardProduct />
    </Suspense>
  );
}
