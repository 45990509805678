import { Row, Col } from "react-bootstrap";

import NavBar from "../navBar";
import BigCard from "./bigCard";
import CardProduct from "./card";
import Footer from "./footer";

function Home(){
    return (
    <div>
        <Row>
            {/* Colum 1 */}
            <Col>
                <NavBar/>
                    <div className='p-2'>
                        <BigCard
                        Title={"Ropa"}
                        URL={"https://panterasteam.com/store/api/public/img/static/Playera-6.jpg"}/>
                        
                    </div>
            </Col>
            {/* Colum 1 */}
            <Col className='p-2'>
            <BigCard
                        Title={"Uniformes"}
                        URL={"https://panterasteam.com/store/api/public/img/static/UNIFORMES1.jpeg"}/>
                
            </Col>
        </Row>
        <Row className="p-2">
            {/* Colum 1 */}
            <Col>
                    <div className='p-2'>
                        <BigCard
                        Title={"Souvenirs"}
                        URL={"https://panterasteam.com/store/api/public/img/static/taza.jpeg"}/>
                        
                    </div>
            </Col>
            {/* Colum 1 */}
            <Col className='p-2'>
            <BigCard
                        Title={"Accesorios"}
                        URL={"https://panterasteam.com/store/api/public/img/static/accesorios.jpeg"}/>
                
            </Col>
        </Row>
        {/* Final ROW */}
        <div className='p-2'>
            <h1 style={{ color:"white"}}>NUEVOS PRODUCTOS</h1>
            <Row>
                <CardProduct/>
            </Row>
        </div>
        <Footer/>
    </div>
    );
}
export default Home;