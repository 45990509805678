import { Container,Card } from "react-bootstrap";

import './assets/css/bannerFooter.css';

function BannerFooter({title, description,img1,img2}){
    return(
        <Container className="card-footer-container">
            <Card bg="dark">
                <Card.Body>
                    <Card.Text className="p-4">
                        <h1>{title}</h1>
                        <p>{description}</p>
                    </Card.Text>
                </Card.Body>
                <div className="footer-banner-img">
                    <Card.Img variant="bottom" src={img1} />
                    <Card.Img variant="bottom" src={img2} />
                </div>
            </Card>
        </Container>
    );
};
export default BannerFooter;