import { Link } from 'react-router-dom';
import { Container, Navbar,Nav , Button } from 'react-bootstrap';
import '../src/components/assets/css/navBar.css'
function NavBar() {
  return (
    <div className='nav-container'>  
      <Navbar expand="lg">
            <Container>
                <Link to="/home">
                <Navbar.Brand>
                    <img
                        src="https://panterasteam.com/store/api/public/img/static/STORE.png"
                        width="80"
                        height="80"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/home/ropa" className='navLink'><Button><h4>Ropa</h4></Button></Link>
                        <Link to="/home/accesorios" className='navLink'><Button><h4>Accesorios</h4></Button></Link>
                        <Link to="/home/souvenirs" className='navLink'><Button><h4>Souvenirs</h4></Button></Link>                 
                        <Link to="/home/equipo-de-campo" className='navLink'><Button><h4>Equipo de campo</h4></Button></Link>
                        {/* <NavDropdown title="" id="dropdown-basic" className='navLink'>
                            <NavDropdown.Item to="#action/3.1" className='navLink'>Proximamente...</NavDropdown.Item>
                            <NavDropdown.Item to="#action/3.2" className='navLink'>Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item to="#action/3.3" className='navLink'>Something</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item to="#action/3.4" className='navLink'>Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>  );
}

export default NavBar;
