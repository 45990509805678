import { Col, Row, Card, Form } from "react-bootstrap";
//css
import './assets/css/viewCategoria.css';

//components
import NavBar from "../navBar";
// import CardProduct from "./card";
import ProductCard from "./ProductCard";
import BannerFooter from "./bannerfooter";
import Footer from "./footer";

function ViewCategoria({Title,Descripcion,img1,img2,img3}){
    let fetchUrl = `https://panterasteam.com/store/api/src/interfaces/controllers/public.php?categoria=${Title}`;
    const bannerTitle = Title;
    const bannerDescription = Descripcion;
    const img1Banner = img1;
    const img2Banner = img2;
    return(
        
            <Row>
                <NavBar/>
                <header>
                    <div className="title-header">
                        <h1><b>{Title}</b></h1>
                        <p><b>{Descripcion}</b></p>
                    </div>
                    <Row className="header">
                        <div className="header-container">
                            <Col className="header-columns">
                            <img className="header" src={img1} alt="" /> 
                            </Col>
                            <Col className="header-columns">
                            <img className="header" src={img2} alt="" /> 
                            </Col>
                            <Col className="header-columns">
                            <img className="header" src={img3} alt="" /> 
                            </Col>
                        </div>
                    </Row>
                </header>
                {/* <Col className="column-left">
                     <Card className="card-filter" >
                        <Card.Body>
                            <Card.Title><h1><b>Filtrar</b></h1></Card.Title>
                            <div className="filters m-2">
                            <div className="m-4">
                            <h5>Genero</h5>
                            <Form.Check
                                    type="checkbox"
                                    label="Hombre"
                                    id='disabled-default-checkbox'
                                />
                            <Form.Check
                                    type="checkbox"
                                    label="Mujer"
                                    id='disabled-default-checkbox'
                                />
                            <Form.Check
                                    type="checkbox"
                                    label="Niño"
                                    id='disabled-default-checkbox'
                                />
                            <Form.Check
                                    type="checkbox"
                                    label="Niña"
                                    id='disabled-default-checkbox'
                                />
                            </div>
                            <div className="m-4">
                            <h5>Tipo</h5>
                            <Form.Check
                                    type="checkbox"
                                    label="Casual"
                                    id='disabled-default-checkbox'
                                />
                            <Form.Check
                                    type="checkbox"
                                    label="Training"
                                    id='disabled-default-checkbox'
                                />
                            <Form.Check
                                    type="checkbox"
                                    label="Uniformes"
                                    id='disabled-default-checkbox'
                                />
                            <Form.Check
                                    type="checkbox"
                                    label="Panteras"
                                    id='disabled-default-checkbox'
                                />
                            </div>
                            <div className="m-4">
                            <h5>Color</h5>
                            <Form.Control
                                type="color"
                                id="exampleColorInput"
                                defaultValue="#563d7c"
                                title="Choose your color"
                            />
                            </div>
                            <div className="m-4">
                                <Form.Label><h5>Calificacion</h5></Form.Label>
                                <Form.Range />
                            </div>
                            </div>
                        </Card.Body>
                    </Card> 
                </Col> */}
                <Col className="column-rigth">
                    <ProductCard
                    url={fetchUrl}/>
                </Col>
                
                    <BannerFooter
                    title={bannerTitle}
                    description={bannerDescription}
                    img1={img1Banner}
                    img2={img2Banner}/>
                    <Footer/>
                
            </Row>

            
        
        

    );
}
export default ViewCategoria;