import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Row, Col, Image, Card, ListGroup, Form, Button, Container } from "react-bootstrap";

import NavBar from "../navBar";
import CardProduct from "./card";
import Footer from './footer';

import './assets/css/viewProduct.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ViewProduct() {
    const query = useQuery();
    const id = query.get('id');
    const [product, setProduct] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    
    useEffect(() => {
        if (id) {
            fetch(`https://panterasteam.com/store/api/src/interfaces/controllers/public.php?id=${id}`)
                .then(response => response.json())
                .then(data => {
                    if(data && data.length >0){
                        setProduct(data);
                        setImageSrc(data[0].path);
                    }
                })
                .catch(error => console.error('Error fetching product:', error));
            
        }
        
    }, [id]);
    
    
    

    if (!product) {
        return <div>Loading...</div>;
    }


    const handleClick = () => {
        const url = `https://wa.me/+5212227050088?text=Hola me interesa este producto : ${encodeURIComponent(product[0].nombre)} - ${encodeURIComponent(product[0].clave)} - https://store.panterasteam.com/`;
        window.open(url, "_blank");
    };

    const handleImageClick = (item) => {
        
        console.log(item.path);
        setImageSrc(item.path);
        // Aquí puedes agregar la lógica adicional que necesites para el click en las imágenes
    };
    

    return (
        <div>
            <NavBar />
            <Row>
                <Col className="product-img">
                    <div className="img-container">
                        <Image src={`${imageSrc}`} alt="" thumbnail />
                    </div>
                    <Row className="imgs-from-product">
                        <Col xs={6}>
                            {product[1].map((item, index) => (
                                <Image
                                    key={index}
                                    src={`${item.path}`}
                                    rounded
                                    onClick={()=> handleImageClick(item)}

                                />
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Col className="producto-details">
                    <div className="card-container">
                        <Card className="card-product">
                            <Card.Body>
                                <Card.Title><h1>{product[0].nombre}</h1></Card.Title>
                                <Card.Text>
                                    {product[0].descripcion}
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>
                                    <Row className="size-quantity-container">
                                        <Col xs={1}>
                                            <h6>$</h6>
                                        </Col>
                                        <Col xs={5}>
                                            <h4>{product[0].precio} pantera pesitos</h4>
                                        </Col>
                                        {/* <Col xs={4} className="size-container">
                                            <Form.Label>Talla</Form.Label>
                                            <Form.Select aria-label="Default select example">
                                                <option>Seleccione la talla</option>
                                                <option value="1">XS</option>
                                                <option value="2">S</option>
                                                <option value="3">M</option>
                                                <option value="4">G</option>
                                                <option value="5">XG</option>
                                            </Form.Select>
                                        </Col>
                                        <Col xs={2} className="quantity-container">
                                            <Form.Label>Cantidad</Form.Label>
                                            <Form.Control type="Number" placeholder="" defaultValue="1" />
                                        </Col> */}
                                    </Row>
                                </ListGroup.Item>
                                {/* <ListGroup.Item>Color
                                    <Form>
                                        <Form.Check
                                            inline
                                            label=""
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-1`}
                                            className="verde"
                                        />
                                        <Form.Check
                                            inline
                                            label=""
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-2`}
                                            className="azul"
                                        />
                                        <Form.Check
                                            inline
                                            label=""
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-3`}
                                            className="rojo"
                                        />
                                    </Form>
                                </ListGroup.Item> */}
                            </ListGroup>
                            <Card.Body className="body-product">
                                <Button onClick={handleClick}>
                                    <h1>!LO QUIERO!</h1>
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
            <Container className="productos-similares">
                <div><h1 style={{ color: 'white' }}>Productos Similares</h1></div>
                <CardProduct />
            </Container>
            <Footer/>
        </div>
    );
}

export default ViewProduct;
