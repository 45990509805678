import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Container } from "react-bootstrap";
import './assets/css/bigCard.css';

function BigCard({Title, URL}) {
    return (
        <Link to={`/home/${Title}`}>
        <Container>
            
            <Card className="big-card bg-dark text-white">
                <Card.Img src={`${URL}`} alt="Card image" />
                <Card.ImgOverlay className="big-card-overlay">
                    <Card.Title className="big-card-title"><h1>{Title}</h1></Card.Title>
                    <Card.Text className="big-card-text">Last updated 3 mins ago</Card.Text>
                </Card.ImgOverlay>
            </Card>
        </Container>
        </Link>
    );
}

export default BigCard;