import React, { useState, useEffect } from 'react';
import { Container, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ProductCard({ url }) {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container>
      <Row className='cont-cards'>
        {data?.map((producto) => (
          <Card bg='#ffff' style={{ width: '14rem' }} className='card-product p-2 m-2' key={producto.idProducto}>
            <Link to={`/producto?id=${producto.idProducto}`}>
              <Card.Img variant="top" src={producto.path} />
              <Card.Body>
                <Card.Title><b>{producto.nombre}</b></Card.Title>
              </Card.Body>
              <Card.Footer className='text-muted'>${producto.precio}</Card.Footer>
            </Link>
          </Card>
        ))}
      </Row>
    </Container>
  );
}

export default ProductCard;
