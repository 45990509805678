import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./components/home";
import ViewCategoria from "./components/vistaCategoria";
import ViewProduct from "./components/viewProduct";

import './App.css';

function App(){
    return ( 
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            
            <Route path="/home" element={<Home />} />
            
            <Route path="/home/accesorios" element={<ViewCategoria
            Title={"Accesorios"}
            Descripcion={"¡Descubre nuestra colección de accesorios exclusivos que elevarán tu estilo! Desde elegantes bolsos y carteras, deslumbrantes joyas, y modernas gafas de sol, hasta relojes de moda y sombreros con personalidad, cada pieza está diseñada para complementar tu look y destacar en cualquier ocasión. ¡Encuentra tu accesorio perfecto y luce espectacular!"} 
            img1={"https://panterasteam.com/store/api/public/img/static/accesorios.jpeg"}
            img2={"https://panterasteam.com/store/api/public/img/static/accesorios.jpeg"}
            img3={"https://panterasteam.com/store/api/public/img/static/accesorios.jpeg"}/>} />
            
            <Route path="/home/ropa" element={<ViewCategoria
            Title={"Ropa"}
            Descripcion={"¡Prepárate para el juego con nuestra exclusiva línea de ropa para fútbol americano! Diseñada pensando en el rendimiento y la comodidad, nuestra colección te ofrece todo lo que necesitas para destacarte en el campo y lucir genial fuera de él."} 
            img1={"https://panterasteam.com/store/api/public/img/static/Playera-6.jpg"}
            img2={"https://panterasteam.com/store/api/public/img/static/pijama.jpeg"}
            img3={"https://panterasteam.com/store/api/public/img/static/chamarra.jpeg"}/>}/>
            
            <Route path="/home/souvenirs" element={<ViewCategoria
            Title={"Souvenirs"}
            Descripcion={"¡Descubre la pasión del fútbol americano con nuestros increíbles souvenirs promocionales! Desde camisetas y gorras con el logotipo de tu equipo favorito hasta balones autografiados y artículos coleccionables únicos, tenemos todo lo que necesitas para mostrar tu lealtad y entusiasmo. Nuestros productos, ideales para cualquier fanático, son perfectos para regalos, concursos y eventos especiales. ¡No pierdas la oportunidad de llevar contigo un pedazo de la acción y apoyar a tu equipo con estilo!"} 
            img1={"https://panterasteam.com/store/api/public/img/static/pulseras.jpeg"}
            img2={"https://panterasteam.com/store/api/public/img/static/taza.jpeg"}
            img3={"https://panterasteam.com/store/api/public/img/static/pulseras.jpeg"}/>}/>
            
            
            <Route path="/Home/equipo-de-campo" element={<ViewCategoria
            Title={"Equipo de campo"}
            Descripcion={"¡Equipa tu campo con lo mejor en artículos promocionales de fútbol americano! Ofrecemos desde conos y marcadores hasta redes de práctica y balones personalizados, todos diseñados para llevar tu entrenamiento al siguiente nivel. Con la calidad y durabilidad que necesitas, nuestros productos promocionales son ideales para equipos de todas las edades y niveles. ¡Haz que cada entrenamiento cuente y muestra tu espíritu de equipo con nuestro equipamiento de campo profesional!"}
            img1={"https://panterasteam.com/store/api/public/img/static/homecascos2.png"}
            img2={"https://panterasteam.com/store/api/public/img/static/equipamiento2.png"}
            img3={"https://panterasteam.com/store/api/public/img/static/equipamiento1.png"}/>} />
            
            <Route path="/Home/uniformes" element={<ViewCategoria
            Title={"Uniformes"}
            Descripcion={"¡Viste a tu equipo con elegancia y orgullo con nuestros uniformes promocionales de fútbol americano! Diseñados para destacar en el campo y fuera de él, nuestros uniformes combinan estilo, comodidad y durabilidad. Desde jerseys personalizables hasta pantalones y calcetas coordinadas, cada pieza está meticulosamente elaborada para reflejar la identidad y la pasión de tu equipo. Ya sea para competiciones, prácticas o eventos especiales, nuestros uniformes no solo mejoran el rendimiento, sino que también fortalecen el sentido de pertenencia y camaradería entre los jugadores."}
            img1={"https://panterasteam.com/store/api/public/img/static/uniforme22.jpeg"}
            img2={"https://panterasteam.com/store/api/public/img/static/UNIFORMES1.jpeg"}
            img3={"https://panterasteam.com/store/api/public/img/static/UNIFORME2.jpeg"}/>} />
            
            <Route path="/producto" element={<ViewProduct/>}/>
            
        </Routes>
    </Router>
    );
};
export default App;